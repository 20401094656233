/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import flagIcon from '../../../assets/Images/signin/India.png';
import Select from 'react-select';
import tradly from 'tradly';
import axios from 'axios';
import { Auth_text } from '../../../constant/Translation/Auth_text';

const PhoneForm = ({ setNumber, setDialCode }) => {
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    axios.get('/api/countries').then((res) => {
      setCountries(res.data.countries);
    });
  }, [0]);

  return (
    <div className=" w-full  flex flex-col  justify-center items-center">
      <div className=" w-full   h-12 mb-5   bg-transparent  rounded-input   text-white outline-none placeholder-white flex justify-center items-center">
        {countries && (
          <PhoneInput
            onlyCountries={countries?.map((country) => {
              return country.code2.toLowerCase();
            })}
            country={countries && countries[0]?.code2.toLowerCase()}
            onChange={(mobile, country, e) => {
              setNumber(mobile);
              setDialCode(country.dialCode);
            }}
            name={Auth_text.login.mobile_number}
          />
        )}
      </div>
    </div>
  );
};

export default PhoneForm;
