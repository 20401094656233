import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../Shared/PopUp/PopUp';
import PasswordForm from './PasswordForm';
import { useSelector } from 'react-redux';
import {
  authSelector,
  clearState,
  verifyUser,
} from '../../../store/feature/authSlice';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import tradly from 'tradly';
import SuccessPopUp from '../../Shared/PopUp/Success';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import axios from 'axios';
import { Auth_text } from '../../../constant/Translation/Auth_text';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

const VerificationForm = () => {
  const [is_loading, setIs_loading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success_message, setSuccess_message] = useState('');
  const [code, setCode] = useState(null);
  const [verifyId, setVerifyId] = useState(null);
  const { isFetching, isError, errorMessage, isSuccess, login } =
    useSelector(authSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    setVerifyId(getCookie(`${TYPE_CONSTANT.DOMAIN}_new_user_verify_id`));
  }, []);

  const closePopUP = () => {
    dispatch(clearState());
    setShowError(false);
    setError_message('');
  };
  const closeSuccessPopUP = () => {
    setShowSuccess(false);
    setSuccess_message('');
  };

  // resend otp
  const resendOTP = () => {
    setIs_loading(true);

    const user_data = JSON.parse(
      getCookie(`${TYPE_CONSTANT.DOMAIN}_new_user_register_data`)
    );

    axios.post('/api/auth/resend_otp', { data: user_data }).then((res) => {
      if (!res.data.code) {
        setVerifyId(res.data.verify_id);
        setCookie(
          `${TYPE_CONSTANT.DOMAIN}_new_user_verify_id`,
          res.data.verify_id
        );
        setIs_loading(false);
        setShowSuccess(true);
        setSuccess_message(Auth_text.otp.resend_message);
      }
    });
  };

  // Verify
  const verifyClick = () => {
    if (code === null) {
      setShowError(true);
      setError_message(Auth_text.otp.enter_verification_code_here);
      return false;
    }
    if (code.length !== 6) {
      setShowError(true);
      setError_message(Auth_text.otp.enter_verification_code_here);
      return false;
    }
    if (verifyId === '') {
      setShowError(true);
      setError_message(Auth_text.otp.could_not_set_password);
      return false;
    }
    const verification = {
      verify_id: verifyId,
      code: code,
    };
    dispatch(verifyUser({ prams: verification, key: 'abncg' })).then((res) => {
      if (!res.payload.code) {
        deleteCookie(`${TYPE_CONSTANT.DOMAIN}_new_user_verify_id`);
        deleteCookie(`${TYPE_CONSTANT.DOMAIN}_new_user_register_data`);
        push_data_layer({
          event: 'verify_page',
          data: {
            first_name: res.payload?.user?.firstName,
            last_name: res.payload?.user?.lastName,
            email: res.payload?.user?.email ? res.payload?.user?.email : '',
            mobile: res.payload?.user?.mobile ? res.payload?.user?.mobile : '',
            dial_code: res.payload?.user?.dial_code
              ? res.payload?.user?.dial_code
              : '',
            step: 'signup finish',
          },
        });
        if (router.query.to) {
          router.push(router.query.to);
        } else {
          router.push('/');
        }
      }
    });
  };

  //   useEffect(() => {
  //     if (isSuccess && login) {

  //     }
  //   }, [isSuccess, login, router]);

  return (
    <div className="w-full    ">
      {is_loading && <CustomLoading />}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showSuccess && (setShowSuccess(false), setSuccess_message(''));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp
                message={success_message}
                closePopUP={closeSuccessPopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      <h2 className="    text-center text-white text-[30px] md:text-[40px] font-semibold mb-4">
        {Auth_text.otp.email_verification}
      </h2>
      <p className="   text-center text-white text-xl  font-semibold ">
        {Auth_text.otp.enter_verification_code_here}
      </p>
      <div className=" mt-24  w-full  ">
        <PasswordForm code={code} setCode={setCode} />

        <div className="w-full ">
          {TYPE_CONSTANT.ONBOARDING_CONFIGS.otp_verification_note && (
            <div
              className=" mt-2 bg-green-100 border-t-2 border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
              role="alert"
            >
              <div className="flex items-start">
                <svg
                  className="flex-none fill-current h-4 w-4 text-green-500 mr-4 mt-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>

                <div className="flex-grow">
                  <p className="text-sm">
                    {TYPE_CONSTANT.ONBOARDING_CONFIGS.otp_verification_note}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className=" w-full mt-[44px] flex justify-center items-center">
          <button
            className=" w-full   min-h-[24px] flex flex-wrap  justify-center items-center bg-transparent   text-white  font-medium text-base  gap-3 "
            onClick={() => resendOTP()}
          >
            {Auth_text.otp.did_not_receive_code}
            <span className="font-semibold  ">{Auth_text.otp.resend_code}</span>
          </button>
        </div>
        <div className=" w-full mt-12 flex flex-col justify-center items-center">
          <button
            className=" mb-8 w-5/6   xs:w-72 h-12 flex justify-center items-center bg-white rounded-button text-primary font-semibold  text-base gap-3"
            onClick={verifyClick}
            id="verify"
          >
            <span> {Auth_text.otp.verify}</span>
            {isFetching && (
              <svg
                className="animate-spin -ml-1   h-5 w-5 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationForm;
