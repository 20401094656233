import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TYPE_CONSTANT } from '../../constant/Web_constant';

import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';

import { useScreenSize } from '../../hooks/useScreenSize';

import Paragraph from '../../components/StaticBlocks/Paragraph';
import CustomCta from '../../components/StaticBlocks/CustomCta';
import EmptyBlock from '../../components/StaticBlocks/EmptyBlock';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

import { AuthDefaultColumnStyles } from '../../constant/default_styles/AuthPageDefaultStyle';
import AuthForm from '../../components/Auth/AuthForm';
import OnBoardingImage from '../../components/Auth/Onboarding/OnBoardingImage';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import HeadingBlock from '../../components/StaticBlocks/HeadingBlock';
import DescriptionBlock from '../../components/StaticBlocks/DescriptionBlock ';
import ImageBlock from '../../components/StaticBlocks/ImageBlock';
import ImageDestinationBlock from '../../components/StaticBlocks/ImageDestinationBlock';
import VideoBlock from '../../components/StaticBlocks/VideoBlock';
import TitleDescriptionButton from '../../components/StaticBlocks/TitleDescriptionButton';
import OPPSetup from '../../components/Opp/OPPSetup';
import StripeSetup from '../../components/Stripe/StripeSetup';
import { common_blocks_cases } from './BlockDecide/CommonBlockCase';

const AuthPagesLayout = ({ auth_from_type }) => {
  const { width, height } = useScreenSize();
  //select blocks
  const DynamicAuthStyle = TYPE_CONSTANT.PAGES?.auth;

  //  component_decide
  const component_decide = ({
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
  }) => {
    const _styles = styles ?? data?.styles ?? {};
    const section = {
      order_number,
      block,
      block_data_key,
      fetch_by,
      data,
      isStatic,
      extra_data,
      styles,
    };
    switch (block) {
      case 'auth_forms':
        return <AuthForm data={data} styles={styles} type={auth_from_type} />;
      case 'onboarding_image':
        return <OnBoardingImage data={data} styles={styles} />;
      case 'paragraph':
        return <Paragraph data={data} styles={styles} />;
      case 'cta':
        return <CustomCta data={data} styles={styles} />;
      case 'empty_block':
        return <EmptyBlock data={data} styles={styles} />;
      case 'heading_title':
        return data?.enabled && <HeadingBlock data={data} styles={_styles} />;

      case 'description_block':
        return (
          data?.enabled && <DescriptionBlock data={data} styles={_styles} />
        );
      case 'image_block':
        return data?.enabled && <ImageBlock data={data} styles={_styles} />;
      case 'image_with_destination_block':
        return (
          data?.enabled && (
            <ImageDestinationBlock data={data} styles={_styles} />
          )
        );
      case 'video_featured':
        return data?.enabled && <VideoBlock data={data} styles={_styles} />;
      case 'title_desc_button':
        return (
          data?.enabled && (
            <TitleDescriptionButton data={data} styles={_styles} />
          )
        );
      case 'opp_setup_button':
        return isEnabled && <OPPSetup data={data} styles={styles} />;
      case 'stripe_setup_button':
        return isEnabled && <StripeSetup data={data} styles={styles} />;
      default:
        return common_blocks_cases({ section });
    }
  };

  return (
    <>
      {/* Rows */}

      <div
        className={` relative   mx-auto`}
        style={get_dynamic_styles(
          responsive_design_styles({
            parent_group: DynamicAuthStyle?.styles,
            child_group_name: 'box_styles',
            width,
          })
        )}
        id="auth"
      >
        {/* Rows */}

        {DynamicAuthStyle?.schema?.map((row) => {
          const {
            columns,
            current_editor_version = '',
            ...column_styles
          } = row || {};
          const row_styles = responsive_row_styles({
            parent_group: row,
            width,
          });

          return (
            <div
              style={{
                display: row_styles?.display,
                gridTemplateColumns: dynamic_text_convert({
                  main_text: row_styles?.columns_box_percentage,
                  check_by: '-1%',
                  value: '',
                }),
                ...get_dynamic_styles(row_styles),
              }}
            >
              {/* Columns */}
              {columns.map((column, index) => {
                const current_column_style = responsive_column_styles({
                  parent_group: column_styles,
                  index,
                  width,
                });

                return (
                  <div
                    style={{
                      display: current_column_style?.display
                        ? current_column_style?.display
                        : 'flex',
                      ...get_dynamic_styles_with_default_value(
                        current_column_style,
                        AuthDefaultColumnStyles
                      ),
                    }}
                  >
                    {column?.map((item) => {
                      return (
                        component_decide({ ...item }) &&
                        component_decide({ ...item })
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AuthPagesLayout;
