import React from 'react';
import SignInForm from './SignIN/SignInForm';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import SignUpForm from './SignUp/SignUpForm';
import ForgotPasswordForm from './ForgotPassword/ForgotPasswordForm';
import SetPasswordForm from './ForgotPassword/SetPasswordForm';
import VerificationForm from './Verification/VerificationForm';

const AuthForm = ({ styles, type }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {type === 'sign_in' && <SignInForm />}
      {type === 'sign_up' && <SignUpForm />}
      {type === 'forgot_pass' && <ForgotPasswordForm />}
      {type === 'set_pass' && <SetPasswordForm />}
      {type === 'verification' && <VerificationForm />}
    </div>
  );
};

export default AuthForm;
