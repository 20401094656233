import OtpInput from 'react-otp-input';
const OTPForm = ({ code, setCode }) => {
  return (
    <div action="" className="   w-full  flex justify-center  mx-auto">
      <OtpInput
        value={code}
        onChange={(otp) => setCode(otp)}
        className=" otp-input  w-[45px] h-[45px] xs:w-[50px] xs:h-[50px]    md:w-[54px] md:h-[54px]  lg:w-[64px]  lg:h-[64px]   bg-transparent border  border-white  rounded-full   text-white outline-none placeholder-white  text-center text-xl font-semibold  mr-2 xs:mr-4 focus:border-white focus:ring-0  "
        numInputs={6}
        inputStyle="block w-full p-0 !text-white"
        separator={<span></span>}
        isInputNum={true}
      />
    </div>
  );
};

export default OTPForm;
