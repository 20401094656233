/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { uuid } from 'uuidv4';
import { useDispatch } from 'react-redux';
import {
  authSelector,
  clearState,
  signIn,
  verifyUserEmail,
} from '../../../store/feature/authSlice';
import PopUp from '../../Shared/PopUp/PopUp';
import * as EmailValidator from 'email-validator';
import { useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRouter } from 'next/dist/client/router';
import tradly from 'tradly';
import SuccessPopUp from '../../Shared/PopUp/Success';

import CustomLoading from '../../Shared/Loading/CustomLoading';
import axios from 'axios';
import { Auth_text } from '../../../constant/Translation/Auth_text';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import OTPForm from '../OTP/OTPForm';

const SetPasswordForm = ({ general_configs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pass, setPass] = useState(null);
  const [confirm_password, setConfirm_password] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success_message, setSuccess_message] = useState('');
  const dispatch = useDispatch();
  const router = useRouter();

  const { isFetching, isSuccess, errorMessage, isError } =
    useSelector(authSelector);

  const closePopUP = () => {
    dispatch(clearState());
    setShowError(false);
    setError_message('');
  };

  const closeSuccessPopup = () => {
    setShowSuccess(false);
    setSuccess_message('');

    if (router.query.to) {
      router.push(`/sign-in?to=${router.query.to}`);
    } else {
      router.push('/sign-in');
    }
  };

  const clickVerify = () => {
    setIsLoading(true);
    if (verificationCode === null) {
      setShowError(true);
      setError_message(Auth_text.passwordreset.fill_allfields);
      setIsLoading(false);
      return false;
    }
    if (verificationCode.length < 6) {
      setShowError(true);
      setError_message(Auth_text.otp.Invalid_otp_code);
      setIsLoading(false);
      return false;
    }
    if (pass === null) {
      setShowError(true);
      setError_message(Auth_text.passwordreset.enter_new_password);
      setIsLoading(false);
      return false;
    }
    if (pass.length < 6) {
      setShowError(true);
      setError_message(Auth_text.passwordreset.password_min_char);
      setIsLoading(false);
      return false;
    }
    if (pass !== confirm_password) {
      setShowError(true);
      setError_message(Auth_text.passwordreset.enter_same_password);
      setIsLoading(false);
      return false;
    }

    const users = {
      verify_id: router.query.verify_id,
      code: verificationCode,
      password: pass,
    };
    axios
      .post('/api/auth/set_password', { users })
      .then((res) => {
        setIsLoading(false);
        setShowSuccess(true);
        setSuccess_message(Auth_text.passwordreset.password_updated);
      })
      .catch((error) => {
        setShowError(true);
        setError_message(error.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full   py-0 ">
      {isLoading && <CustomLoading />}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccess && (
        <OutsideClickHandler
          onOutsideClick={() => {
            showSuccess && closeSuccessPopup();
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <SuccessPopUp
                message={success_message}
                closePopUP={closeSuccessPopup}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      <p className=" px-[34px]  md:px-24  text-center text-white text-xl  font-semibold ">
        {dynamic_translation_set(Auth_text.forgotpassword.otp_send_info)}
      </p>
      <div className=" mt-12 px-[34px]  ">
        <div className=" flex flex-col  justify-center items-center gap-8">
          <OTPForm code={verificationCode} setCode={setVerificationCode} />
          <input
            type="password"
            className=" w-full   h-12   bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
            placeholder={Auth_text.passwordreset.enter_new_password}
            required
            onChange={(e) => setPass(e.target.value)}
          />
          <input
            type="password"
            className=" w-full   h-12   bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
            placeholder={Auth_text.passwordreset.enter_same_password}
            required
            onChange={(e) => setConfirm_password(e.target.value)}
          />
        </div>
        <div className=" mt-12 flex flex-col justify-center items-center">
          <button
            className=" mb-8  w-full  xs:w-72 h-12 flex justify-center items-center bg-white rounded-button text-primary font-semibold  text-base gap-3"
            onClick={clickVerify}
          >
            {Auth_text.passwordreset.update}
            {isFetching && (
              <svg
                className="animate-spin -ml-1 h-5 w-5 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordForm;
