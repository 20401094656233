import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import 'swiper/css/bundle';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { get_data_by_current_language } from '../../../utils/GetDataByLanguage';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const OnBoardingImage = ({ data, styles }) => {
  return (
    <div className="relative" style={get_dynamic_styles(styles?.main_styles)}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        style={get_dynamic_styles(styles?.box_styles)}
      >
        {data?.sliders?.map((list, i) => {
          return (
            <SwiperSlide
              key={i}
              style={get_dynamic_styles(styles?.card_styles)}
            >
              {list.image && (
                <img
                  src={list.image}
                  alt="onboardingImage"
                  style={get_dynamic_styles(styles?.image)}
                />
              )}
              {styles?.title && (
                <p style={get_dynamic_styles(styles?.title)}>
                  {get_data_by_current_language({
                    data_list: list?.translated_data,
                    key_name: 'title',
                    default_data: list?.title,
                  })}
                </p>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default OnBoardingImage;
