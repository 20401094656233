/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  clearState,
  signUp,
  socialSignIn,
  userAttribute,
} from '../../../store/feature/authSlice';
import EmailForm from './EmailForm';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../Shared/PopUp/PopUp';
import * as EmailValidator from 'email-validator';
import { uuid } from 'uuidv4';
import { useRouter } from 'next/dist/client/router';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import PhoneForm from './PhoneForm';
import { isValidPhoneNumber } from 'react-phone-number-input';
import dynamic from 'next/dynamic';
import Loading from '../../Shared/Loading/Loading';
import { Auth_text } from '../../../constant/Translation/Auth_text';
import { General_text } from '../../../constant/Translation/General';
import { push_data_layer } from '../../../constant/GTMDatalayer';
import { Menubar_text } from '../../../constant/Translation/Heading';
import { getCookie, setCookie } from 'cookies-next';
import { decryptDataWithAES } from '../../../constant/sdk_set';
import Attributes from './Attributes';
import { validate_attribute_data } from '../../../utils/AttributeDataValidation';
const SocialLoginForm = dynamic(() => import('./SocialLoginForm'), {
  ssr: false,
});

const SignUpForm = ({ general_configs }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  //
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [reTypePassword, setReTypePassword] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const [number, setNumber] = useState(null);
  const [dialcode, setDialCode] = useState(null);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [attributeData, setAttributeData] = useState(null);

  const {
    isFetching,
    isError,
    isSuccess,
    errorMessage,
    isSocialLoginFetching,
    attributes,
  } = useSelector(authSelector);

  //
  useEffect(() => {
    dispatch(
      userAttribute({
        prams: {
          type: 'users',
          metadata: { sign_up: true },
        },
      })
    );
  }, []);

  //

  const closePopUP = () => {
    dispatch(clearState());
    setShowError(false);
    setError_message('');
  };

  // Sign up submission
  const signup_click = (signup_data, type) => {
    if (type === 'google_signin') {
      const uUid = decryptDataWithAES(
        decryptDataWithAES(getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`))
      );
      const users = {
        uuid: uUid,
        provider: 'google',
        token: signup_data?.access_token,
        platform: 'web',
        type: 'customer',
      };

      dispatch(socialSignIn({ prams: users })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'sign_up',
            data: {
              method: 'google',
            },
          });
          if (router.query.to) {
            router.push(router.query.to);
          } else {
            router.push('/');
          }
        }
      });
    } else if (type === 'facebook_signin') {
      const uUid = decryptDataWithAES(
        getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
      );

      const users = {
        uuid: uUid,
        provider: 'facebook',
        token: signup_data?.accessToken,
        platform: 'web',
        type: 'customer',
      };

      dispatch(socialSignIn({ prams: users })).then((res) => {
        if (!res.payload.code) {
          push_data_layer({
            event: 'sign_up',
            data: {
              method: 'facebook',
            },
          });
          if (router.query.to) {
            router.push(router.query.to);
          } else {
            router.push('/');
          }
        }
      });
    } else {
      if (TYPE_CONSTANT?.AUTH_TYPE === 1) {
        if (firstName === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.first_name} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (lastName === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.last_name} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (email === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.email} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (!EmailValidator.validate(email)) {
          setShowError(true);
          setError_message(Auth_text.signup.enter_valid_emailid);
          return false;
        }
        if (
          TYPE_CONSTANT.GENERAL_CONFIGS?.enable_mobile_in_email_signup &&
          number === null
        ) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.mobile_number} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (
          TYPE_CONSTANT.GENERAL_CONFIGS?.enable_mobile_in_email_signup &&
          !isValidPhoneNumber(`+${number}`)
        ) {
          setShowError(true);
          setError_message(Auth_text.signup.enter_valid_phoneno);
          return false;
        }

        if (password === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.password} ${Auth_text.signup.required}`
          );
          return false;
        }

        if (reTypePassword === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.re_enter_password} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (reTypePassword !== password) {
          setShowError(true);
          setError_message(Auth_text.signup.password_mismatch);
          return false;
        }
        if (password?.length < 6) {
          setShowError(true);
          setError_message(Auth_text.signup.password_min_char);
          return false;
        }

        if (
          validate_attribute_data({
            attributes,
            attributeData,
            setShowError,
            setError_message,
          }) === false
        ) {
          return false;
        }

        const uUid = decryptDataWithAES(
          getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
        );
        const users = {
          user: {
            uuid: uUid,
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            type: 'customer',
          },
        };
        if (TYPE_CONSTANT.GENERAL_CONFIGS?.enable_mobile_in_email_signup) {
          users.user.mobile = number.slice(dialcode.length);
          users.user.dial_code = dialcode;
        }
        if (selectedCountryId) {
          users.user.country_id = selectedCountryId;
        }

        if (attributes?.length > 0) {
          users.user.attributes = attributeData;
        }

        dispatch(signUp({ prams: users })).then((res) => {
          if (!res.payload.code) {
            setCookie(
              `${TYPE_CONSTANT.DOMAIN}_new_user_register_data`,
              JSON.stringify(users)
            );

            push_data_layer({
              event: 'sign_up',
              data: {
                method: 'email',
                first_name: firstName,
                last_name: lastName,
                email: email,
                step: 'signup start',
              },
            });
            if (router.query.to) {
              router.push(`/verification?to=${router.query.to}`);
            } else {
              router.push('/verification');
            }
          }
        });
      }
      if (TYPE_CONSTANT?.AUTH_TYPE === 3) {
        if (firstName === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.first_name} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (lastName === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.last_name} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (number === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.mobile_number} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (!isValidPhoneNumber(`+${number}`)) {
          setShowError(true);
          setError_message(Auth_text.signup.enter_valid_phoneno);
          return false;
        }

        if (password === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.password} ${Auth_text.signup.required}`
          );
          return false;
        }

        if (reTypePassword === null) {
          setShowError(true);
          setError_message(
            `${Auth_text.signup.re_enter_password} ${Auth_text.signup.required}`
          );
          return false;
        }
        if (reTypePassword !== password) {
          setShowError(true);
          setError_message(Auth_text.signup.password_mismatch);
          return false;
        }
        if (password.length < 6) {
          setShowError(true);
          setError_message(Auth_text.signup.min_password_validation);

          return false;
        }

        if (
          validate_attribute_data({
            attributes,
            attributeData,
            setShowError,
            setError_message,
          }) === false
        ) {
          return false;
        }

        const uUid = decryptDataWithAES(
          getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
        );
        const users = {
          user: {
            uuid: uUid,
            first_name: firstName,
            last_name: lastName,
            mobile: number.slice(dialcode.length),
            dial_code: dialcode,
            password: password,
            type: 'customer',
          },
        };
        dispatch(signUp({ prams: users })).then((res) => {
          if (!res.payload.code) {
            setCookie(
              `${TYPE_CONSTANT.DOMAIN}_new_user_register_data`,
              JSON.stringify(users)
            );

            push_data_layer({
              event: 'sign_up',
              data: {
                method: 'mobile',
                first_name: firstName,
                last_name: lastName,
                mobile: number.slice(dialcode.length),
                dial_code: dialcode,
                step: 'signup start',
              },
            });
            if (router.query.to) {
              router.push(`/verification?to=${router.query.to}`);
            } else {
              router.push('/verification');
            }
          }
        });
      }
    }
  };

  return (
    <div className="w-full h-auto  flex  flex-col justify-center items-center">
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      <Loading loading={isSocialLoginFetching} />

      {/*  */}
      <p className="    text-center text-white text-xl  font-semibold ">
        {Auth_text.signup.create_your_account}
      </p>

      {/*  */}
      <div className=" mt-8    w-full  ">
        {TYPE_CONSTANT.AUTH_TYPE === 1 && (
          <EmailForm
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setPassword={setPassword}
            setReTypePassword={setReTypePassword}
            setNumber={setNumber}
            setDialCode={setDialCode}
            selectedCountryId={selectedCountryId}
            setSelectedCountryId={setSelectedCountryId}
          />
        )}

        {TYPE_CONSTANT.AUTH_TYPE === 3 && (
          <PhoneForm
            setFirstName={setFirstName}
            setLastName={setLastName}
            setNumber={setNumber}
            setDialCode={setDialCode}
            setPassword={setPassword}
            setReTypePassword={setReTypePassword}
          />
        )}
        {/* Attributes */}

        {attributes?.length > 0 && (
          <Attributes
            attributeData={attributeData}
            setAttributeData={setAttributeData}
            setErrormessage={setError_message}
          />
        )}

        {/*  */}
        <div className=" w-full mt-8 c-md:mt-12 flex flex-col justify-center items-center">
          <button
            className=" mb-4 c-md:mb-8  w-full  xs:w-72 h-12 flex justify-center items-center bg-white rounded-input text-primary font-semibold  text-base gap-4"
            id="signup"
            onClick={signup_click}
          >
            <span> {Auth_text.signup.create}</span>
            {isFetching && (
              <svg
                className="animate-spin -ml-1 ltr:mr-3 rtl:ml-3 h-5 w-5 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>

        {/* Social Sign In  */}
        {(TYPE_CONSTANT.GENERAL_CONFIGS?.google_sign_in ||
          TYPE_CONSTANT.GENERAL_CONFIGS?.facebook_sign_in ||
          TYPE_CONSTANT.ALL_INTEGRATIONS?.google_login ||
          TYPE_CONSTANT.ALL_INTEGRATIONS?.facebook_sign_in) && (
          <SocialLoginForm clickSignUp={signup_click} />
        )}

        {/*  */}
        <div className="text-sm   text-white  text-center mt-8 w-full">
          {Auth_text.signup.signup_agreement.split('${value}')[0] ?? ''}{' '}
          <a
            className="underline"
            href={TYPE_CONSTANT.GENERAL_CONFIGS?.terms_url ?? '#'}
            rel="noreferrer"
            target={'_blank'}
          >
            {Menubar_text.more.terms_condition}
          </a>
          {Auth_text.signup.signup_agreement.split('${value}')[1] ?? ''}{' '}
          <a
            className="underline"
            href={TYPE_CONSTANT.GENERAL_CONFIGS?.privacy_policy_url ?? '#'}
            rel="noreferrer"
            target={'_blank'}
          >
            {Menubar_text.more.privacy_policy}
          </a>
          {Auth_text.signup.signup_agreement.split('${value}')[2] ?? ''}
        </div>

        {/*  */}
        <div className=" mt-8 flex justify-center items-center">
          <Link
            href={
              router.query.to ? `/sign-in?to=${router.query.to}` : '/sign-in'
            }
            passHref
          >
            <button className=" w-full  h-6 flex justify-center items-center bg-transparent   text-white  font-medium text-base  xs:text-xl">
              {Auth_text.signup.already_have_an_account}
              <span className="font-semibold ml-2">
                {Auth_text.signup.login}
              </span>
            </button>
          </Link>
        </div>

        {/*  */}
        <div className=" mt-8 rounded-button flex justify-center items-center ">
          <Link href={'/'} passHref>
            <button className=" w-full  h-6 flex justify-center items-center bg-transparent   text-white  font-medium text-base  xs:text-xl gap-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
              <span className="  mt-1">
                {General_text.general.back_to_home}
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
