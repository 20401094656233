/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Auth_text } from '../../../constant/Translation/Auth_text';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';

const PhoneForm = ({
  setFirstName,
  setLastName,
  setNumber,
  setDialCode,
  setPassword,
  setReTypePassword,
}) => {
  const { tenant_countries_list } = useSelector(authSelector);

  return (
    <div className=" w-full flex flex-col  justify-center items-center">
      <input
        type="text"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:outline-none focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.first_name}
        required
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        type="text"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:outline-none focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.last_name}
        required
        onChange={(e) => setLastName(e.target.value)}
      />
      <div className=" w-full   h-12 mb-5   bg-white bg-transparent   rounded-input   text-white outline-none placeholder-white flex justify-center items-center">
        {tenant_countries_list && (
          <PhoneInput
            onlyCountries={tenant_countries_list?.map((country) => {
              return country.code2.toLowerCase();
            })}
            country={
              tenant_countries_list &&
              tenant_countries_list[0]?.code2.toLowerCase()
            }
            onChange={(mobile, country, e) => {
              setNumber(mobile);
              setDialCode(country.dialCode);
            }}
            name={Auth_text.signup.mobile_number}
          />
        )}
      </div>
      <input
        type="password"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:outline-none focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:outline-none focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.re_enter_password}
        required
        onChange={(e) => setReTypePassword(e.target.value)}
      />
    </div>
  );
};

export default PhoneForm;
