/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { uuid } from 'uuidv4';
import { useDispatch } from 'react-redux';
import {
  authSelector,
  clearState,
  signIn,
  verifyUserEmail,
} from '../../../store/feature/authSlice';
import PopUp from '../../Shared/PopUp/PopUp';
import * as EmailValidator from 'email-validator';
import { useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRouter } from 'next/dist/client/router';
import { Auth_text } from '../../../constant/Translation/Auth_text';
import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { decryptDataWithAES } from '../../../constant/sdk_set';
import PhoneForm from './PhoneForm';
import { isValidPhoneNumber } from 'react-phone-number-input';

const ForgotPasswordForm = ({ general_configs }) => {
  //
  const [email, setEmail] = useState(null);
  const [number, setNumber] = useState(null);
  const [dialcode, setDialCode] = useState(null);
  //
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');
  const dispatch = useDispatch();
  const router = useRouter();

  const { isFetching, isSuccess, errorMessage, isError } =
    useSelector(authSelector);

  const closePopUP = () => {
    dispatch(clearState());
    setShowError(false);
    setError_message('');
  };

  const clickVerify = () => {
    if (TYPE_CONSTANT.AUTH_TYPE === 1 && email === null) {
      setShowError(true);
      setError_message(Auth_text.forgotpassword.enter_your_email);
      return false;
    }
    if (TYPE_CONSTANT.AUTH_TYPE === 1 && !EmailValidator.validate(email)) {
      setShowError(true);
      setError_message(Auth_text.forgotpassword.enter_your_email);
      return false;
    }
    if (TYPE_CONSTANT.AUTH_TYPE === 3 && number === null) {
      setShowError(true);
      setError_message(Auth_text.login.enter_valid_phoneno);
      return false;
    }
    if (TYPE_CONSTANT.AUTH_TYPE === 3 && !isValidPhoneNumber(`+${number}`)) {
      setShowError(true);
      setError_message(Auth_text.login.enter_valid_phoneno);
      return false;
    }

    const uUid = decryptDataWithAES(
      getCookie(`${TYPE_CONSTANT.DOMAIN}_default_uuid`)
    );

    let forgot_pass_data = {};

    if (TYPE_CONSTANT.AUTH_TYPE === 1) {
      forgot_pass_data = {
        user: {
          email: email,
        },
      };
    }

    if (TYPE_CONSTANT.AUTH_TYPE === 3) {
      forgot_pass_data = {
        user: {
          mobile: number.slice(dialcode.length),
          dial_code: dialcode,
        },
      };
    }

    dispatch(verifyUserEmail({ prams: forgot_pass_data })).then((res) => {
      if (!res.payload.code) {
        if (router.query.to) {
          router.push(
            `/forgot-password/set-password?verify_id=${res.payload.verify_id}&to=${router.query.to}`
          );
        } else {
          router.push({
            pathname: '/forgot-password/set-password',
            query: { verify_id: res.payload.verify_id },
          });
        }
      }
    });
  };

  return (
    <div className="w-full  h-auto">
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {/*  */}
      <p className="  text-center text-white text-xl  font-semibold ">
        {Auth_text.forgotpassword.forgot_your_password}
      </p>

      {/*  */}
      <div className=" mt-24 w-full  ">
        {TYPE_CONSTANT.AUTH_TYPE === 1 && (
          <div className=" w-full flex justify-center">
            <input
              type="text"
              className=" w-full   h-12 mb-6 bg-transparent border  border-white   rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
              placeholder={Auth_text.forgotpassword.enter_your_email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        )}
        {TYPE_CONSTANT.AUTH_TYPE === 3 && (
          <PhoneForm setDialCode={setDialCode} setNumber={setNumber} />
        )}{' '}
        <div className=" w-full mt-12 flex flex-col justify-center items-center">
          <button
            className=" mb-8  w-full  xs:w-72 h-12 flex justify-center items-center bg-white rounded-button text-primary font-semibold  text-base gap-3"
            onClick={clickVerify}
          >
            {Auth_text.forgotpassword.verify}
            {isFetching && (
              <svg
                className="animate-spin -ml-1   h-5 w-5 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
        <div className=" w-full mt-[68px] flex justify-center items-center">
          <Link
            href={
              router.query.to ? `/sign-in?to=${router.query.to}` : '/sign-in'
            }
            passHref
          >
            <button className=" w-full  h-6 flex justify-center items-center bg-transparent   text-white  font-medium text-base  xs:text-xl  rtl:flex-row-reverse ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mr-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                />
              </svg>

              <span className="font-semibold  ">{Auth_text.login.login}</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
