/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Auth_text } from '../../../constant/Translation/Auth_text';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/feature/authSlice';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { getCookie, setCookie } from 'cookies-next';
import { currency_list } from '../../../data/CurrencyData';

const EmailForm = ({
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  setReTypePassword,
  setNumber,
  setDialCode,
  selectedCountryId,
  setSelectedCountryId,
}) => {
  const { tenant_countries_list, countries_list } = useSelector(authSelector);

  // available currencies
  const [currencies, setCurrencies] = useState(TYPE_CONSTANT.CURRENCIES);

  // on Select country
  const onSelectCountry = (country_value) => {
    // Currency selection
    const country = countries_list.filter(
      (country) => country.id == country_value
    )[0];
    const selected_country_currency = currencies.filter(
      (currency) => currency.code == country?.currency_en
    );

    if (selected_country_currency?.length > 0) {
      setCookie(
        `${TYPE_CONSTANT.DOMAIN}_default_currency`,
        selected_country_currency[0]?.code
      );
    }

    //
    setSelectedCountryId(country_value);
  };

  return (
    <div className="w-full h-auto flex flex-col  justify-center items-center">
      <input
        type="text"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:outline-none focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.first_name}
        required
        onChange={(e) => setFirstName(e.target.value)}
      />
      <input
        type="text"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.last_name}
        required
        onChange={(e) => setLastName(e.target.value)}
      />
      <input
        type="text"
        className=" w-full   h-12 mb-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.email}
        required
        onChange={(e) => setEmail(e.target.value)}
      />
      {tenant_countries_list?.length > 0 &&
        TYPE_CONSTANT.GENERAL_CONFIGS?.enable_mobile_in_email_signup && (
          <div className=" w-full   h-12 mb-5  bg-transparent text-black  rounded-input     outline-none placeholder-white flex justify-center items-center">
            {tenant_countries_list && (
              <PhoneInput
                onlyCountries={tenant_countries_list?.map((country) => {
                  return country.code2.toLowerCase();
                })}
                country={
                  tenant_countries_list?.filter((cn) => cn.default == true)
                    .length > 0 &&
                  tenant_countries_list
                    ?.filter((cn) => cn.default == true)[0]
                    ?.code2.toLowerCase()
                }
                onChange={(mobile, country, e) => {
                  setNumber(mobile);
                  setDialCode(country.dialCode);
                }}
                name={Auth_text.signup.mobile_number}
              />
            )}
          </div>
        )}
      <input
        type="password"
        className=" w-full   h-12 mb-6  bg-transparent  border border-white rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.password}
        required
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        className=" w-full   h-12  bg-transparent  border border-white rounded-input p-3 text-white outline-none placeholder-white focus:border-white focus:ring-0"
        placeholder={Auth_text.signup.re_enter_password}
        required
        onChange={(e) => setReTypePassword(e.target.value)}
      />

      {countries_list?.length > 0 &&
        TYPE_CONSTANT.GENERAL_CONFIGS?.enable_country_in_profile && (
          <select
            className=" w-full   h-12 mt-6 bg-transparent border  border-white  rounded-input p-3 text-white outline-none placeholder-white focus:outline-none focus:border-white focus:ring-0"
            onChange={(e) => onSelectCountry(e.target.value)}
          >
            <option
              className="py-2 bg-transparent text-primary"
              disabled
              selected
            >
              {Auth_text.signup.country_selection}
            </option>
            {countries_list &&
              countries_list.map((country) => {
                return (
                  <option
                    className="py-2 bg-transparent text-primary"
                    key={country.code2}
                    value={country.id}
                    selected={selectedCountryId == country.id ? true : false}
                  >
                    {country.name}
                  </option>
                );
              })}
          </select>
        )}
    </div>
  );
};

export default EmailForm;
